import React, { useContext } from "react";
import { Discount, Membership, Voucher } from "../../../hooks/useCompany";
import PackageDetails from "./PackageDetails";
import PurchasePackageButton from "./PurchasePackageButton";
import { useNavigate } from "react-router-dom";
import { Box, Card } from "@mui/material";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";

interface IProps {
  voucher: Discount;
}
const PackagePurchaseDetails: React.FC<IProps> = ({ voucher }) => {
  const navigate = useNavigate();
  const { company , marketplaceName} = useContext(CheckoutContext) as CheckoutContextProps;

  

  const  getPackageValue = ()=>{
    if(voucher.type === "membership"){
      return (voucher as Membership).maxUsage
    }

    if(voucher.type === "credit"){
      return (voucher as Voucher).credit
    }

    if(voucher.type === "duration"){
      return (voucher as Voucher).duration
    }
  }


  const handleClick = () => {
    if(marketplaceName){
      navigate(
        `/packagePurchase?companyId=${company?.id}&marketplaceName=${marketplaceName}&packageValue=${getPackageValue()}&packagePrice=${voucher.price}&packageName=${voucher.name || ""}&packageId=${voucher.id}&packageType=${voucher.type}`
    );
    }else{
      navigate(
        `/packagePurchase?companyId=${company?.id}&packageValue=${getPackageValue()}&packagePrice=${voucher.price}&packageName=${voucher.name || ""}&packageId=${voucher.id}&packageType=${voucher.type}`
      );
    }
  };

  return (
    <Box sx={{ mb: 3, width: "100%" }} data-testid="package-purchase-details">
      <Card
        sx={{
          width: "100%",
          padding: "16px 20px",
          textAlign: "center",
          mb: 2,
          boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
          borderRadius: "16px",
        }}
      >
        <PackageDetails
          packagePrice={voucher.price}
          packageValue={getPackageValue()!}
          packageName={voucher?.name}
          type={voucher.type}
          packageDuration={voucher.type === 'membership' ? (voucher as Membership).days : 0}
        />
      </Card>
      <PurchasePackageButton
        handleClick={handleClick}
        label={"Purchase Package"}
      />
    </Box>
  );
};
export default PackagePurchaseDetails;
