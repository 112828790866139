import { Typography } from "@mui/material";
import React from "react";
import { PackageDetailsItemWrapper } from "../style";

interface IProps {
  label: string;
  value: string;
  valueColor?: string;
}
const PackageDetailsItem: React.FC<IProps> = ({ label, value, valueColor }) => {
  return (
    <PackageDetailsItemWrapper
      direction={"row"}
      spacing={3}
      justifyContent={"space-between"}
      alignItems="center"
      valuecolor={valueColor || "#808080"}
      data-testid="package-details-item"
    >
      <Typography data-testid="package-details-item-label" className="package-details-item-label">{label}</Typography>
      <Typography data-testid="package-details-item-value" className="package-details-item-value">{value}</Typography>
    </PackageDetailsItemWrapper>
  );
};

export default PackageDetailsItem;
