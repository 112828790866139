import {
  MenuItem,
  Typography,
  Select,
  SelectChangeEvent,
  Stack,
  Chip,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { AddonItemWrapper } from "./style";

import { Addon } from "../../hooks/useAddons";

interface IProps {
  addon: Addon;
  setSelectedAddonsQuantities: React.Dispatch<
    React.SetStateAction<Map<string, number>>
  >;
  selectedDurationTime?: number;
}
const AddonItem: React.FC<IProps> = ({
  addon,
  setSelectedAddonsQuantities,
  selectedDurationTime
}) => {
  const [value, setValue] = useState<string>("0");
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    setSelectedAddonsQuantities((oldQuantities) => {
      oldQuantities.set(addon.id, Number(event.target.value));
      return oldQuantities;
    });
  };
  const addonPrice = addon.enableServiceDuration ? addon.price * (selectedDurationTime! / addon.serviceDuration!) : addon.price
  return (
    <AddonItemWrapper data-testid="addon-item">
      <Box className="chip-container">
        <Chip
          label={addon?.isRent ? "Rent" : "Purchase"}
          color="primary"
          variant="outlined"
          className="chip"
        />
      </Box>

      <Box className="image-container">
        <img src={addon.image} alt={addon.name} />
      </Box>

      <Box className="content-container">
        <Typography className="addon-name">
          {addon.name}
        </Typography>

        <Typography className="addon-price">
          {`${addonPrice} ${addon.currency}`}
        </Typography>

        <Stack className="quantity-stack">
          <Typography component="span" className="quantity-label">
            Add Quantity
          </Typography>
          <Select
            value={value}
            onChange={handleChange}
            className="quantity-select"
            data-testid="addon-quantity-select"
          >
            <MenuItem key="0" value="0">
              0
            </MenuItem>
            {Array.from({ length: addon.maxQuantity }).map((_, index) => (
              <MenuItem key={index + 1} value={`${index + 1}`}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Box>
    </AddonItemWrapper>
  );
};

export default AddonItem;
