import React, { useContext, useState } from "react";
import { Card, Checkbox, Typography, Stack, Link, useTheme } from "@mui/material";
import { ITermsCard } from "./Types";
import PrimaryBtn from "../../FormCollection/PrimaryBtn";
import { Link as ReactLink, useSearchParams } from "react-router-dom";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import CheckoutFrames from "../CheckoutFrames/CheckoutFrames";
import { DSW_COMPANY_ID } from "../../../Utils/constants";

const TermsAgreementCard: React.FC<ITermsCard> = ({
  onClick,
  isLoading,
  packageValidationOutput,
  totalPrice,
  error,
  disabled,
}) => {
  const theme = useTheme();
  const [userAgreed, setUserAgreed] = useState(false);
  const [searchParams] = useSearchParams();
  const checkBoxHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAgreed(event.target.checked);
  };

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const getPaymentText = () => {
    if (totalPrice === 0 && !packageValidationOutput) {
      return "Book Now";
    }

    if (
      !packageValidationOutput ||
      packageValidationOutput?.type === "promocode" ||
      packageValidationOutput?.cartTotalAfterApplyingVoucher !== 0
    ) {
      return "Proceed to Payment";
    }
    return "Book with Package";
  };
  return (
    <Card
      sx={{
        width: "100%",
        padding: "24px 20px",
        borderRadius: "16px",
        boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
        marginBottom: "40px",
      }}
    >
      <Stack sx={{ flexDirection: "row", marginBottom: "17px",  width: "100%"}}>
        <Checkbox onChange={checkBoxHandler} id="terms-checkbox" sx={{ "data-testid": "terms-checkbox" }}/>
        <Typography
          style={{ color: "#808080", fontWeight: 500 }}
          component={"label"}
          htmlFor="terms"
        >
          I agree to{" "}
          <Typography sx={{ fontWeight: 500 }} component={"span"}>
            the{" "}
            <Link component={ReactLink} to={`${company?.footerPagesLinks?.privacy}`} target="_blank" onClick={(e) => {
              if (!company?.footerPagesLinks?.privacy) {
                e.preventDefault();
              }
            }}>
              privacy policy
            </Link>
            ,{" "}
            <Link component={ReactLink} to={`${company?.footerPagesLinks?.terms}`} target="_blank" onClick={(e) => {
              if (!company?.footerPagesLinks?.terms) {
                e.preventDefault();
              }
            }}>
              terms & conditions
            </Link>
            {(company?.cancellation_policy && company?.cancellation_policy > 0) ? (
              <>
                ,{" "}
                <Link
                  component={ReactLink}
                  to={`${company?.footerPagesLinks?.refund}`}
                  target="_blank"
                  onClick={(e) => {
                    if (!company?.footerPagesLinks?.refund) {
                      e.preventDefault();
                    }
                  }}
                >
                  cancellation policy
                </Link>
              </>
            ) : <>{" "}</>}
            {(company?.cancellation_policy && company?.cancellation_policy > 0) ? (
              <span style={{ color: theme.palette.primary.main }}>
                (Bookings can be cancelled online only {company?.cancellation_policy} hours prior to the booking date)
              </span>
            ) : <></>}
          </Typography>
        </Typography>
      </Stack>
      {(company?.id === DSW_COMPANY_ID &&
      totalPrice !== undefined &&
      totalPrice > 0) ? (
        <CheckoutFrames
          handleCheckout={onClick}
          totalPrice={totalPrice}
          userAgreed={userAgreed}
        />
      ) : (
        <PrimaryBtn
          fullWidth
          disabled={!userAgreed || isLoading || disabled}
          color="error"
          variant="contained"
          id="checkout-button"
          onClick={() => onClick()}
          isLoading={isLoading}
          text={getPaymentText()}
        />
      )}
      {error && (
        <Typography className="user-information-error">{error}</Typography>
      )}
    </Card>
  );
};

export default TermsAgreementCard;
