import { Session } from "../../../hooks/useClassSessions";
import ContentLoader from "../../shared/ContentLoader";
import ClassSessionChip from "./ClassSessionChip";
import { ClassSessionSelectorWrapper } from "./style";
import Heading from "../../shared/Heading";
import { alphabeticalSort } from "../../../Utils/sort";
import dayjs from "dayjs";
import { getClassFullDate } from "../../../Utils/format";
import { CheckoutContext } from "../../../context/CheckoutContext";
import { CheckoutContextProps } from "../../../context/CheckoutContext";
import { useContext } from "react";

interface IProps {
  sessions: Session[];
  isLoading: boolean;
  type: "fun" | "fitness" | "coach" | "kids" | "pods";
}

const ClassSessionSelector: React.FC<IProps> = ({ sessions, isLoading, type }) => {
  const { 
    servicesMap, 
    selectedService
  } = useContext(CheckoutContext) as CheckoutContextProps;
  const service = servicesMap?.get(selectedService!)!;

  const filteredSessions = sessions
  .filter((session) => session.isActive)
  .filter((session) => getClassFullDate(session).isAfter(dayjs(), 'day') || getClassFullDate(session).isSame(dayjs(), 'day'))
  .sort((a, b) => alphabeticalSort(`${a.date}${a.startTime}`, `${b.date}${b.startTime}`));
  
  const getHeadingTitle = () => {
    const titles = {
      fitness: {
        empty: "No Classes Available",
        available: "",
      },
      coach: {
        empty: "No Coaches Available",
        available: "",
      },
      fun: {
        empty: service?.funActivityDisclaimer || "Oh no, there aren't any upcoming sessions. Check back soon!",
        available: "",
      },
      kids: {
        empty: "No Kids Activities Available",
        available: "",
      },
      pods: {
        empty: "No People of Determination Activities Available",
        available: "",
      },
    };

    return filteredSessions.length === 0 ? titles[type].empty : titles[type].available;
  };



  return (
    <ClassSessionSelectorWrapper>
      <ContentLoader isLoading={isLoading}>
        <>
          <Heading heading={getHeadingTitle()} />
          {filteredSessions.map((session) => (
            <ClassSessionChip key={session.id} session={session} />
          ))}
        </>
      </ContentLoader>
    </ClassSessionSelectorWrapper>
  );
};

export default ClassSessionSelector;

