import React from 'react';
import Box from '@mui/material/Box';

import padelImg from '../../../assets/danube/courts/padel.png';
import badmintonImg from '../../../assets/danube/courts/badminton.png';
import tableTennisImg from '../../../assets/danube/courts/table-tennis.png';
import cricketImg from '../../../assets/danube/courts/cricket.png';
import footballImg from '../../../assets/danube/courts/football.png';
import tennisImg from '../../../assets/danube/courts/tennis.png';
import { Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';

interface CourtOption {
  id: string;   
  title: string;
  imageUrl: string;
  bookingUrl: string;
  altText: string;
}

const courtOptions: CourtOption[] = [
  {
    id: 'padel',
    title: 'Padel Court',
    imageUrl: padelImg,
    bookingUrl: '/padel-court-booking/',
    altText: 'Padel Court Booking'
  },
  {
    id: 'badminton',
    title: 'Badminton Court',
    imageUrl: badmintonImg,
    bookingUrl: '/badminton-court-booking/',
    altText: 'Badminton Court Booking'
  },
  {
    id: 'tableTennis',
    title: 'Table Tennis',
    imageUrl: tableTennisImg,
    bookingUrl: '/table-tennis-court-booking/',
    altText: 'Table Tennis Court Booking'
  },
  {
    id: 'cricket',
    title: 'Indoor Cricket',
    imageUrl: cricketImg,
    bookingUrl: '/indoor-cricket-ground-booking/',
    altText: 'Indoor Cricket Ground Booking'
  },
  {
    id: 'football',
    title: 'Football',
    imageUrl: footballImg,
    bookingUrl: '/football-pitch-booking/',
    altText: 'Football Pitch Booking'
  },
  {
    id: 'tennis',
    title: 'Tennis',
    imageUrl: tennisImg,
    bookingUrl: '/tennis-court-booking/',
    altText: 'Tennis Court Booking'
  }
];

const Courts: React.FC = () => {

    const isMd = useMediaQuery("(min-width:600px)");
    const isLg = useMediaQuery("(min-width:1024px)");
  return (
    <>
    <Stack
        direction={isLg ? "row" : "column"}
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          width: "100%",
          padding: isLg ? "2rem" : "1rem",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Typography 
          variant={isLg ? "h4" : isMd ? "h5" : "h6"} 
          align="center" 
          fontFamily="Dubai-Bold"
        >
          LARGEST INDOOR SPORTS FACILITY IN DUBAI
      </Typography>
      </Stack>
          <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: 3,
      }}
    >
      {courtOptions.map((court) => (
        <Box
          key={court.id}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            '&:hover img': {
              transform: 'scale(1.1)',
            },
          }}
        >
          <Box
            component="a"
            href={court.bookingUrl}
            sx={{
              display: 'block',
              position: 'relative',
              height: '100%',
            }}
          >
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
              <Box
                component="img"
                src={court.imageUrl}
                alt={court.altText}
                sx={{
                  width: '100%',
                  height: 180,
                  objectFit: 'cover',
                  transition: 'transform 0.3s',
                }}
                loading="lazy"
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
    </>
  );
};

export default Courts;
