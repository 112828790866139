import React from 'react';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import DFCHattaImage from '../../../assets/images/DFC-hatta.jpg';
import DFC2024Image from '../../../assets/images/DFC-2024.jpg';

interface FeaturedItem {
    title: string;
    subtitle: string;
    imageUrl: string;
    linkUrl: string;
    buttonText: string;
}

const featuredItems: FeaturedItem[] = [
    {
        title: 'ABOUT',
        subtitle: '30x30',
        imageUrl: DFCHattaImage,
        linkUrl: 'https://www.dubaifitnesschallenge.com/about-us/',
        buttonText: 'Learn More',
    },
    {
        title: 'WHAT’S ON',
        subtitle: '2024',
        imageUrl: DFC2024Image,
        linkUrl: 'https://www.dubaifitnesschallenge.com/en/whats-on/',
        buttonText: 'Learn More',
    },
];

const FeaturedItems: React.FC = () => {
    return (
        <Box sx={{ backgroundColor: '#03bdfd', position: 'relative' }}>
            <Container maxWidth="lg" sx={{ py: 4, position: 'relative', zIndex: 1 }}>
                <Grid container spacing={3} justifyContent="center">
                    {featuredItems.map((item, index) => (
                        <Grid item xs={12} sm={6} md={5} key={index}>
                            <FeaturedItemCard item={item} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

const FeaturedItemCard: React.FC<{ item: FeaturedItem }> = ({ item }) => (
    <Box 
        sx={{ 
            position: 'relative', 
            height: 0,
            paddingTop: '80%', // Reduced from 100% to 80% to decrease height
            borderRadius: 2, 
            overflow: 'hidden',
            cursor: 'pointer',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
                transform: 'scale(1.05)',
            },
        }}
    >
        <Box
            component="img"
            src={item.imageUrl}
            alt={item.title}
            sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%', 
                height: '100%', 
                objectFit: 'cover' 
            }}
        />
        <Box sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease-in-out',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
        }}>
            <Typography 
                variant="h6" 
                color="white" 
                sx={{ 
                    textShadow: '2px 2px 4px rgba(0,0,0,0.8)', 
                    fontWeight: 'bold',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                    fontFamily: "Dubai-Bold"
                }}
            >
                {item.title}
            </Typography>
            <Typography 
                variant="subtitle1" 
                color="#A0FC4F" 
                sx={{ 
                    textShadow: '2px 2px 4px rgba(0,0,0,0.8)', 
                    fontWeight: 'bold',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                    fontFamily: "Dubai-Bold"
                }}
            >
                {item.subtitle}
            </Typography>
            <Button
                variant="contained"
                href={item.linkUrl}
                sx={{
                    mt: 1,
                    backgroundColor: '#A0FC4F',
                    color: 'black',
                    '&:hover': { backgroundColor: '#8CE03C' },
                    fontSize: '0.8rem',
                    padding: '4px 8px',
                    fontFamily: "Dubai-Bold"
                }}
            >
                {item.buttonText}
            </Button>
        </Box>
    </Box>
);

export default FeaturedItems;
