import React from 'react';
import CursoalHeader from "../../components/Home/CursoalHeader";
import Categories from "../../components/Home/Categories";
import { categories, data } from "../../components/Home/FakeData";
import { useAuth } from "../../context/AuthContext";
import {
  BaseUrlToCompany,
  DSW_COMPANY_ID,
  WINTER_CAMP_COMPANY_ID,
  RegistrationStep,
} from "../../Utils/constants";
import { isValidUser } from "../../Utils/validate";
import ContentLoader from "../../components/shared/ContentLoader";
import Register from "../register";
import MakeBooking from "../../components/Home/MakeBooking";
import { Box, Stack } from "@mui/material";
import Footer from "../../components/shared/footer";

const DubaiSportsWorldHome = () => {
  const { user, isAuthLoading } = useAuth();


  return (
    <ContentLoader isLoading={isAuthLoading}>
      <Stack sx={{ width: "100%", overflow: "hidden" }}>
        <CursoalHeader />

        {!isValidUser(user) && (
          <>
            <Box
              sx={{
                width: "100%",
                paddingX: "24px",
                paddingY: "50px",
                paddingBottom: "0px",
              }}
            >
              <Register
                registrationStep={RegistrationStep.Login}
                redirectToCalendar={false}
              />
            </Box>
            <Box
              sx={{
                height: "100px",
                width: "100%",
                paddingX: "24px",
                paddingY: "50px",
                backgroundColor: "#E0E0E0",
                clipPath: "polygon(0px 0px, 100% 0px, 100% 40%, 0px 80%)",
              }}
            ></Box>
          </>
        )}

        <MakeBooking />

        {/* Categories */}
        <Categories data={categories} />

        <Footer
          socialLinks={{
            instagram: "https://www.instagram.com/dubaisportsworld/",
            facebook: "https://www.facebook.com/DubaiSportsWorld",
            youtube: "https://www.youtube.com/user/DubaiSportsWorld",
            tiktok: "https://www.tiktok.com/@dubaisportsworld"
          }}
        />
      </Stack>
    </ContentLoader>
  );
};

export default DubaiSportsWorldHome;
