import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";

const StaticHeader = () => {
  const isMd = useMediaQuery("(min-width:600px)");
  const isLg = useMediaQuery("(min-width:1024px)");

  useEffect(() => {
    // Ensure video plays automatically when component mounts
    const video = document.querySelector('.full-width-video') as HTMLVideoElement;
    if (video) {
      video.play();
    }
  }, []);

  return (
    <Stack
      component={"header"}
      sx={{
        position: "relative",
        width: "100%",
        padding: "0",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <video
          className="full-width-video"
          autoPlay
          loop
          muted
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            objectPosition: isLg ? "center 62%" : isMd ? "center 70%" : "center 60%",
          }}
        >
          <source 
            src="https://jjv359.n3cdn1.secureserver.net/wp-content/uploads/2023/01/MoVlog-web-Video-new.mp4" 
            type="video/mp4" 
          />
        </video>
      </Stack>
  
    </Stack>
  );
};

export default StaticHeader;
