import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';

// Import all sponsor logos
import dpWorldLogo from '../../../assets/dfc-sponsors/dp-world-new-500px.png';
import dubaiMunicipalityLogo from '../../../assets/dfc-sponsors/dubai-manicip.png';
import maiDubaiLogo from '../../../assets/dfc-sponsors/mai_dubai_logo_en-ar_en.png';
import rtaLogo from '../../../assets/dfc-sponsors/rta-logo-small.png';
import sunAndSandSportsLogo from '../../../assets/dfc-sponsors/sss_image_logo_124_cmyk_.png';
import brainPerformanceLogo from '../../../assets/dfc-sponsors/the-brain-performance-logo-with-illustration-colour-english.png';
import citiLogo from '../../../assets/dfc-sponsors/citi-logo-bl-500px.png';
import decathlonLogo from '../../../assets/dfc-sponsors/decathlon-logo-bl-500px-new.png';
import dubizzleLogo from '../../../assets/dfc-sponsors/dubizzle-logo-original-english.png';
import emiratesLogo from '../../../assets/dfc-sponsors/emirates-logo-new.png';
import emiratesNBDLogo from '../../../assets/dfc-sponsors/emirates-nbd-logo-bl-500px-new.png';
import etisalatLogo from '../../../assets/dfc-sponsors/e-logo-english.png';
import laRochePosayLogo from '../../../assets/dfc-sponsors/la-roche-posay-logo.png';
import miraLogo from '../../../assets/dfc-sponsors/dark-blue.png';
import talabatLogo from '../../../assets/dfc-sponsors/talabat-logo-english-500px-new.png';
import waslLogo from '../../../assets/dfc-sponsors/wasl-english-logo-500x500.png';
import whoopLogo from '../../../assets/dfc-sponsors/whoop-logo-text-black-english.png';
import kiteBeachLogo from '../../../assets/dfc-sponsors/kite-beach-logo-uat.png';
import dubaiChambersLogo from '../../../assets/dfc-sponsors/dubai-chambers.png';
import emaratLogo from '../../../assets/dfc-sponsors/emarat.png';
import supercareLogo from '../../../assets/dfc-sponsors/supercare-pharmacy-english-500px.png';
import ambulanceLogo from '../../../assets/dfc-sponsors/ambulance.png';
import dubaiKnowledgeLogo from '../../../assets/dfc-sponsors/dubai-knowledge.png';
import policeLogo from '../../../assets/dfc-sponsors/police.png';
import ministryOfEducationLogo from '../../../assets/dfc-sponsors/uae_moedu_brandmark_vertical_500x500.jpg';
import escLogo from '../../../assets/dfc-sponsors/esc-logo.png';
import detLogo from '../../../assets/dfc-sponsors/det-logo-small.png';
import dubaiSportsLogo from '../../../assets/dfc-sponsors/dubai-sports-council-logo.png';
import arnLogo from '../../../assets/dfc-sponsors/arn-logo-vector-0110.png';

interface Sponsor {
    name: string;
    logo: string;
    url: string;
}

interface SponsorCategory {
    title: string;
    sponsors: Sponsor[];
}

const sponsorCategories: SponsorCategory[] = [
    {
        title: "Presenting Partners",
        sponsors: [
            { name: "DP World", logo: dpWorldLogo, url: "https://www.dpworld.com/" },
            { name: "Dubai Municipality", logo: dubaiMunicipalityLogo, url: "https://www.dm.gov.ae/" },
            { name: "Mai Dubai", logo: maiDubaiLogo, url: "https://www.maidubaiwater.com/" },
            { name: "RTA", logo: rtaLogo, url: "https://www.rta.ae/wps/portal/rta/ae/home?lang=en" },
        ]
    },
    {
        title: "Association Partners",
        sponsors: [
            { name: "Sun and Sand Sports", logo: sunAndSandSportsLogo, url: "https://en-ae.sssports.com/home" },
            { name: "The Brain Performance Centre", logo: brainPerformanceLogo, url: "https://braindubai.ae" },
            { name: "Citi Mastercard", logo: citiLogo, url: "https://www.citi.com/" },
            { name: "Decathlon", logo: decathlonLogo, url: "https://decathlon.ae/" },
            { name: "dubizzle", logo: dubizzleLogo, url: "https://www.dubizzle.com/" },
            { name: "Emirates", logo: emiratesLogo, url: "https://www.emirates.com" },
            { name: "Emirates NBD", logo: emiratesNBDLogo, url: "https://www.emiratesnbd.com/en/" },
            { name: "Etisalat", logo: etisalatLogo, url: "https://www.eand.com/en/index.html" },
            { name: "La Roche Posay", logo: laRochePosayLogo, url: "https://www.laroche-posay.me/en" },
            { name: "Mira", logo: miraLogo, url: "https://miraestate.ae/" },
            { name: "Talabat", logo: talabatLogo, url: "https://www.talabat.com/uae" },
            { name: "Wasl", logo: waslLogo, url: "https://www.wasl.ae/en" },
            { name: "WHOOP", logo: whoopLogo, url: "https://www.whoop.com/ae/en/" },
            { name: "KITE BEACH", logo: kiteBeachLogo, url: "https://www.kitebeach.ae" },
        ]
    },
    {
        title: "Official Partners",
        sponsors: [
            { name: "Dubai Chambers", logo: dubaiChambersLogo, url: "https://www.dubaichamber.com/en/home/" },
            { name: "Emarat", logo: emaratLogo, url: "https://www.emarat.ae/" },
            { name: "Supercare Pharmacy", logo: supercareLogo, url: "https://www.super-care.ae/" },
        ]
    },
    {
        title: "Government Partners",
        sponsors: [
            { name: "Dubai Ambulance", logo: ambulanceLogo, url: "https://www.ambulance.gov.ae/" },
            { name: "Dubai Knowledge", logo: dubaiKnowledgeLogo, url: "https://www.khda.gov.ae/en" },
            { name: "Dubai Police", logo: policeLogo, url: "https://www.dubaipolice.gov.ae/wps/portal/home/home" },
            { name: "Ministry of Education", logo: ministryOfEducationLogo, url: "https://www.moe.gov.ae/En/Pages/Home.aspx" },
            { name: "Event's Security Committee", logo: escLogo, url: "#" },
        ]
    },
    {
        title: "Organised By",
        sponsors: [
            { name: "DET", logo: detLogo, url: "https://www.visitdubai.com/en" },
            { name: "DUBAI Sports", logo: dubaiSportsLogo, url: "https://www.dubaisc.ae/en/Pages/default.aspx" },
        ]
    },
    {
        title: "Media Partner",
        sponsors: [
            { name: "A R N", logo: arnLogo, url: "http://arn.ae/" },
        ]
    },
];

const SponsorsPanel: React.FC = () => {
    return (
        <Box id="partners" sx={{ backgroundColor: '#f5f5f5', py: 4 }}>
            <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}> {/* Adjust padding */}
                {sponsorCategories.map((category, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: '#ccc',
                                    my: 4
                                }}
                            />
                        )}
                        <Box mb={4}>
                            <Typography 
                                variant="h5" 
                                component="h5" 
                                sx={{
                                    margin: '0 auto 20px', // Center the title
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    fontSize: '17px',
                                    lineHeight: '19px',
                                    fontWeight: 400,
                                    color: '#5d5d5d',
                                    fontFamily: "Dubai-Regular",
                                    width: '100%',
                                }}
                            >
                                {category.title}
                            </Typography>
                            <Grid 
                                container 
                                spacing={{ xs: 2, sm: 3, md: 4 }} // Responsive spacing
                                justifyContent="center" 
                                alignItems="center" 
                                sx={{ 
                                    marginTop: 2,
                                    width: '100%',
                                    mx: 'auto', // Center the grid
                                }}
                            >
                                {category.sponsors.map((sponsor, sponsorIndex) => (
                                    <Grid item xs={6} sm={4} md={3} key={sponsorIndex} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box
                                            component="a"
                                            href={sponsor.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: { xs: 140, sm: 160, md: 179 }, // Responsive width
                                                height: { xs: 140, sm: 160, md: 179 }, // Responsive height
                                                backgroundColor: '#fff',
                                                borderRadius: '24px',
                                                padding: '20px',
                                                // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                // transition: 'box-shadow 0.3s ease-in-out',
                                                // '&:hover': {
                                                //     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                                // },
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={sponsor.logo}
                                                alt={sponsor.name}
                                                sx={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </React.Fragment>
                ))}
            </Container>
        </Box>
    );
};

export default SponsorsPanel;
