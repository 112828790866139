import React from 'react';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery, Stack } from '@mui/material';

import padelAcademyImg from '../../../assets/danube/academies/padel.jpg';
import footballAcademyImg from '../../../assets/danube/academies/football.jpg';
import multiSportsAcademyImg from '../../../assets/danube/academies/multi-sports.jpg';
import tableTennisAcademyImg from '../../../assets/danube/academies/table-tennis.jpg';
import cricketAcademyImg from '../../../assets/danube/academies/cricket.jpg';
import tennisAcademyImg from '../../../assets/danube/academies/tennis.jpg';

interface ClassOption {
  id: string;
  title: string;
  imageUrl: string;
  bookingUrl: string;
  altText: string;
}

const classOptions: ClassOption[] = [
  {
    id: 'padel-academy',
    title: 'Padel Academy',
    imageUrl: padelAcademyImg,
    bookingUrl: '/academies/padel',
    altText: 'Padel Academy'
  },
  {
    id: 'football-academy',
    title: 'Football Academy',
    imageUrl: footballAcademyImg,
    bookingUrl: '/academies/football',
    altText: 'Football Academy'
  },
  {
    id: 'multi-sports',
    title: 'Multi Sports',
    imageUrl: multiSportsAcademyImg,
    bookingUrl: '/academies/multi-sports',
    altText: 'Multi Sports Academy'
  },
  {
    id: 'tennis-academy',
    title: 'Tennis Academy',
    imageUrl: tennisAcademyImg,
    bookingUrl: '/academies/tennis',
    altText: 'Tennis Academy'
  },
  {
    id: 'cricket-academy',
    title: 'Cricket Academy',
    imageUrl: cricketAcademyImg,
    bookingUrl: '/academies/cricket',
    altText: 'Cricket Academy'
  },
  {
    id: 'table-tennis-academy',
    title: 'Table Tennis Academy',
    imageUrl: tableTennisAcademyImg,
    bookingUrl: '/academies/table-tennis',
    altText: 'Table Tennis Academy'
  }
];

const Academies: React.FC = () => {
  const isMd = useMediaQuery("(min-width:600px)");
  const isLg = useMediaQuery("(min-width:1024px)");

  return (
    <>
      <Stack
        direction={isLg ? "row" : "column"}
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          width: "100%",
          padding: isLg ? "2rem" : "1rem",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Typography 
          variant={isLg ? "h4" : isMd ? "h5" : "h6"} 
          align="center" 
          fontFamily="Dubai-Bold"
        >
          CHECK OUT <span style={{ background: 'linear-gradient(to right, #ff0000, #00ff00)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>OUR ACADEMIES</span>
        </Typography>
      </Stack>

      <Box
       sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: 3,
        gap: 2,
      }}
      >
        {classOptions.map((classOption) => (
          <Box
            key={classOption.id}
           
          >
            <Box
              component="a"
              href={classOption.bookingUrl}
              sx={{
                display: 'block',
                position: 'relative',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <Box

                  component="img"
                  src={classOption.imageUrl}
                  alt={classOption.altText}
                  sx={{
                    width: '100%',
                    height: 580,
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },  
                  }}
                  loading="lazy"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Academies;
