import React from 'react';
import { Box, Typography } from '@mui/material';

interface SponsorImage {
  src: string;
  alt: string;
  width: number;
  height: number;
}

const SponsorsPanel: React.FC = () => {
  const platinumSponsors: SponsorImage[] = [
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2023/08/liv-logo.png",
      alt: "LIV Logo",
      width: 128,
      height: 87
    }
  ];

  const diamondSponsors: SponsorImage[] = [
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/09/3.jpg",
      alt: "Diamond Sponsor 1",
      width: 650,
      height: 470
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2023/01/khaleej-time-1.jpg",
      alt: "Khaleej Times",
      width: 650,
      height: 470
    }
  ];

  const officialSponsors: SponsorImage[] = [
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/09/5.jpg",
      alt: "Official Sponsor 1",
      width: 650,
      height: 470
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/13.jpg",
      alt: "Official Sponsor 2",
      width: 650,
      height: 470
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2023/01/Channel-4-Logo-1-02.jpg",
      alt: "Channel 4",
      width: 651,
      height: 471
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/09/6.jpg",
      alt: "Official Sponsor 4",
      width: 650,
      height: 470
    }
  ];

  const partners: SponsorImage[] = [
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/09/8.jpg",
      alt: "Partner 1",
      width: 650,
      height: 470
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/Emirates-Logo-02.jpg",
      alt: "Emirates Logo",
      width: 651,
      height: 471
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/DSC-Logo-02.jpg",
      alt: "DSC Logo",
      width: 651,
      height: 471
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/Playsight-Logo-02.jpg",
      alt: "Playsight Logo",
      width: 651,
      height: 471
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/PN-Logo-02.jpg",
      alt: "PN Logo",
      width: 651,
      height: 471
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/09/9.jpg",
      alt: "Partner 6",
      width: 650,
      height: 470
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/14-1.jpg",
      alt: "Partner 7",
      width: 650,
      height: 470
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/15.jpg",
      alt: "Partner 8",
      width: 650,
      height: 470
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2022/12/MS-Logo-02.jpg",
      alt: "MS Logo",
      width: 651,
      height: 471
    },
    {
      src: "https://danubesportsworld.com/wp-content/uploads/2023/03/medcare.jpg",
      alt: "Medcare",
      width: 650,
      height: 470
    }
  ];

  return (
    <Box 
      component="section" 
      sx={{ 
        marginTop: 10,
        pb: 8.75,
        '& .sportsman-gradient': {
          background: 'linear-gradient(90deg, #F0B323 0%, #E74C3C 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent'
        }
      }}
    >
      <Box sx={{ 
        maxWidth: 1200,
        mx: 'auto',
        px: { xs: 2, md: 3  }
      }}>
        <Typography 
          variant="h3" 
          align="center"
          sx={{ mb: 4 , marginBottom: 10 }}
        >
          OUR<Box component="strong" sx={{ display: 'inline' }} className="sportsman-gradient"> PARTNERS</Box>
        </Typography>

        {/* Platinum Sponsors */}
        <Box sx={{ mb: 4 }}>
          <Typography 
            variant="h4" 
            align="center"
            sx={{ mb: 3 }}
          >
            <Box component="span" className="sportsman-gradient">PLATINUM</Box> SPONSOR
          </Typography>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gap: 3,
            alignItems: 'center',
            justifyItems: 'center'
          }}>
            {platinumSponsors.map((sponsor, index) => (
              <Box key={index} sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src={sponsor.src}
                  alt={sponsor.alt}
                  sx={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxHeight: 200
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Diamond Sponsors */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" align="center">
            <Box component="span" className="sportsman-gradient">DIAMOND</Box> SPONSOR
          </Typography>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gap: 3,
            alignItems: 'center',
            justifyItems: 'center'
          }}>
            {diamondSponsors.map((sponsor, index) => (
              <Box key={index} sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src={sponsor.src}
                  alt={sponsor.alt}
                  sx={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxHeight: 200
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Official Sponsors */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" align="center">
            <Box component="span" className="sportsman-gradient">OFFICIAL</Box> SPONSOR
          </Typography>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gap: 3,
            alignItems: 'center',
            justifyItems: 'center'
          }}>
            {officialSponsors.map((sponsor, index) => (
              <Box key={index} sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src={sponsor.src}
                  alt={sponsor.alt}
                  sx={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxHeight: 160
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Partners */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" align="center">
            <Box component="span" className="sportsman-gradient">PARTNERS</Box>
          </Typography>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gap: 3,
            alignItems: 'center',
            justifyItems: 'center'
          }}>
            {partners.map((sponsor, index) => (
              <Box key={index} sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src={sponsor.src}
                  alt={sponsor.alt}
                  sx={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxHeight: 150
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SponsorsPanel;
