import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import ContentLoader from "../../components/shared/ContentLoader";

import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import { CartCheckoutWrapper } from "./style";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import PricingCard from "../../components/shared/PricingCard";

import usePackage, {
  Package,
  PackageValidationOutput,
  ValidationOutput,
} from "../../hooks/usePackage";
import DiscountRedemption from "../../components/discount/DiscountRedemption";
import useCart, { CustomerInformation } from "../../hooks/useCart";
import CheckoutForm from "../../components/Checkout/CheckoutForm";
import { Membership } from "../../hooks/useCompany";
import { useAuth } from "../../context/AuthContext";
import TagManager from "react-gtm-module";
import CustomFooter from "../../components/shared/footer/CustomFooter";
import useTitle from "../../hooks/useTitle";
import { addCartItemsEvent } from "../../Utils/gtmEvents";
import CartBookingCard from "../../components/cart/BookingCard";
import BackToHomeButton from "../../components/shared/BackToHomeButton";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../Utils/constants";
import useProfile from "../../hooks/useProfile";
import { parseDateTimeString } from "../../Utils/format";
import { isValidUser } from "../../Utils/validate";

interface IProps {
  title: string;
}
const CartCheckout: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const { company, isCartLoading, bookingCart, selectedService, servicesMap, marketplaceCompany , marketplaceName } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const { user } = useAuth();

  const { getPackages } = usePackage();

  const [packages, setPackages] = useState<Package[]>([]);
  const [memberships, setMembership] = useState<Package[]>([]);
  const [isLoadingVouchers, setIsLoadingVouchers] = useState<boolean>(true);
  const [selectedPackage, setSelectedPackage] = useState<string>("");
  const [selectedPromocode, setSelectedPromocode] = useState<string>("");

  const [packageValidationOutput, setPackageValidationOutput] = useState<
    ValidationOutput | undefined
  >();

  const { getUserBookings } = useProfile();

  useEffect(() => {
    if (bookingCart.length > 0) {
      addCartItemsEvent("begin_checkout", bookingCart);
    }
  }, []);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        setIsLoadingVouchers(true);
        const userPackages = await getPackages();
        setPackages(
          userPackages.filter(
            (val) => val.type === "credit" && val.isActive === true
          )
        );
        setMembership(
          userPackages.filter(
            (val) => val.type === "membership" && val.isActive === true
          )
        );
      } catch (error) {
        setPackages([]);
      }
      setIsLoadingVouchers(false);
    };
    fetchPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getCartSubTotal = useMemo(() => {
    if (packageValidationOutput?.valid) {
      return packageValidationOutput.cartTotalBeforeApplyingVoucher;
    }
    let price = bookingCart.reduce((prev, cur) => {
      if (cur.addonPurchases) {
        let addonsPrice: number = cur.addonPurchases.reduce(
          (prev2, cur2) => prev2 + cur2.paidAmount,
          0
        );

        return prev + addonsPrice + cur.price * cur.quantity;
      } else return prev;
    }, 0);

    return price;
  }, [bookingCart, packageValidationOutput]);

  const getCartTotal = useMemo(() => {
    if (packageValidationOutput?.valid) {
      return packageValidationOutput.cartTotalAfterApplyingVoucher;
    }
    let price = bookingCart.reduce((prev, cur) => {
      if (cur.addonPurchases) {
        let addonsPrice: number = cur.addonPurchases.reduce(
          (prev2, cur2) => prev2 + cur2.paidAmount,
          0
        );


        return prev + addonsPrice + cur.price * cur.quantity;
      } else return prev;
    }, 0);

    return price;
  }, [bookingCart, packageValidationOutput]);

  const getDiscount = useMemo(() => {
    if (packageValidationOutput?.valid) {
      return (
        packageValidationOutput.cartTotalBeforeApplyingVoucher -
        packageValidationOutput.cartTotalAfterApplyingVoucher
      );
    }

    return 0;
  }, [bookingCart, packageValidationOutput]);

  const { checkoutCart } = useCart();
  const { checkPackageValidity } = usePackage();

  const handleClick = async (customerInformation?: CustomerInformation) => {
    try {
      if (company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && isValidUser(user)) {
        const bookings = await getUserBookings();
        const upcomingBookings = bookings.flatMap(cart => 
          cart.bookings.filter(booking => 
            parseDateTimeString(booking.date, booking.startTime) >= dayjs()
          )
        );

        if (upcomingBookings.length >= 3) {
          toast("Only 3 court bookings are allowed per person per week", {
            theme: "colored",
            type: "error",
          });
          return;
        }
      }

      // Proceed with original checkout logic
      let selectedDiscount = "";
      if (
        packageValidationOutput?.valid &&
        ["credit", "duration", "membership"].includes(packageValidationOutput?.type)
      ) {
        selectedDiscount = selectedPackage;
      } else if (
        packageValidationOutput?.valid &&
        packageValidationOutput?.type === "promocode"
      ) {
        selectedDiscount = selectedPromocode || selectedPackage;
      }
      addCartItemsEvent("add_payment_info", bookingCart);
      checkoutCart(selectedDiscount, customerInformation);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    if (!memberships || memberships.length === 0) return;

    const applyMembership = async () => {
      const selectedMembership = memberships[0];
      if (selectedMembership.serviceId !== selectedService) return;

      try {
        const voucherValidation = await checkPackageValidity({
          companyId: company ? company.id : "",
          cartId: bookingCart.length > 0 ? bookingCart[0].cartId : "",
          voucherCode: selectedMembership.code,
        });
        setSelectedPackage(selectedMembership.code);
        setPackageValidationOutput(voucherValidation);
      } catch (error) {
        setPackageValidationOutput(undefined);
      }
    };
    applyMembership();
  }, [memberships, isCartLoading]);


  const service = servicesMap?.get(selectedService)

  const handleAddnewBooking = () => {
    if (service?.type === "coach" && selectedService) {
      navigate(`/Coach?companyId=${company?.id}&serviceId=${selectedService}&branchId=${service.branchId}`)
    } else if (service?.type === "class" && service.subType === "fun" && selectedService) {
      navigate(`/fun-activities?companyId=${company?.id}&serviceId=${selectedService}&branchId=${service.branchId}`)
    } else if (service?.type === "standard" && service.subType === "kids" && selectedService) {
      navigate(`/KidsArea?companyId=${company?.id}&serviceId=${selectedService}&branchId=${service.branchId}`)
    } else if (service?.type === "standard" && service.subType === "gym" && selectedService) {
      navigate(`/Gym?companyId=${company?.id}&serviceId=${selectedService}&branchId=${service.branchId}`)
    } else if (service?.type === "class" && service.subType === "fitness" && selectedService) {
      navigate(`/Class?companyId=${company?.id}&serviceId=${selectedService}&branchId=${service.branchId}`)
    } else if (service?.type === "class" && service.subType === "kids" && selectedService) {
      navigate(`/KidsActivities?companyId=${company?.id}&serviceId=${selectedService}&branchId=${service.branchId}`)
    } else if (service?.type === "standard" && selectedService) {
      navigate(`/calendar?companyId=${company?.id}&serviceId=${selectedService}&branchId=${service.branchId}`)
    } else {
      navigate(`/calendar?companyId=${company?.id}`)
    }
  }

  const [searchParams] = useSearchParams();

  const marketplaceCompanyId = searchParams.get("marketplaceCompanyId");

  const returnToMarketplace = ()=>{
    navigate(`/MarketplaceCalendar?companyId=${marketplaceCompanyId}&marketplaceName=${marketplaceName}`);
  }

  const isDFCCompany = company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && bookingCart.length > 0;

  console.log("[cartCheckout] bookingCart", bookingCart);

  return (
    <ContentLoader isLoading={isCartLoading || isLoadingVouchers}>
      <CartCheckoutWrapper>
        {(!!bookingCart.length) ? (
          <>
            {(!!marketplaceName) ? 
              <Box className="buttons">
                <div style={{ margin: "21px auto 0 0" }}>
                  <PrimaryBtn
                    onClick={returnToMarketplace}
                    text="Back"
                    sx={{
                      fontSize: "16px",
                      height: "35px",
                      marginBottom: "5px"
                    }}
                  />
                </div>
              </Box>
            : (!isDFCCompany &&
              <Box className="buttons">
                <div style={{ margin: "21px auto 0 0" }}>
                  <PrimaryBtn
                    onClick={handleAddnewBooking}
                    text="Add Another Booking"
                    sx={{
                      fontSize: "16px",
                      height: "35px",
                    }}
                  />
                </div>
              </Box>
            )}
            <Stack sx={{ gap: "20px", marginBottom: "48px", width: "100%" }}>
              {bookingCart.map((booking) => (
                <CartBookingCard booking={booking} key={booking.id} showRemoveFromCartOption={true} />
              ))}
            </Stack>
            
            {!company?.hideDiscountRedemptionSection && (
              <DiscountRedemption
                packages={packages}
                packageValidationOutput={packageValidationOutput}
                setPackageValidationOutput={setPackageValidationOutput}
                selectedPackage={selectedPackage}
                setSelectedPackage={setSelectedPackage}
                selectedPromocode={selectedPromocode}
                setSelectedPromocode={setSelectedPromocode}
              />
            )}

            <PricingCard
              subTotal={getCartSubTotal}
              total={getCartTotal}
              discount={getDiscount}
              vat={0}
            />

            <CheckoutForm
              handleClick={handleClick}
              isLoading={isCartLoading}
              packageValidationOutput={packageValidationOutput}
              totalPrice={getCartTotal}
            />
          </>
        ) : (
          <BackToHomeButton />
        )}

        {company?.hasFooter && <CustomFooter company={marketplaceCompany || company}/>}
      </CartCheckoutWrapper>
    </ContentLoader>
  );
};

export default CartCheckout;
