import React, { useContext, useEffect, useRef, useState } from "react";
import useCart, { Booking } from "../../hooks/useCart";
import ContentLoader from "../shared/ContentLoader";
import { Stack, Typography } from "@mui/material";
import PricingCard from "../shared/PricingCard";
import useProfile from "../../hooks/useProfile";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useInjectQualtricsScript from "../../hooks/useInjectQualtricsScript";
import { calculateTotals, mapBookingsWithAddons, generateCancellationMessage } from "../../Utils/cartUtils";
import { triggerPurchaseEvent } from "../../Utils/gtmEvents";
import CartBookingCard from "./BookingCard";

interface IProps {
  cartId: string;
  shouldRedirectToCancel?: boolean;
  bookingId?: string;
}
const CartCheckoutDetails: React.FC<IProps> = ({ cartId, shouldRedirectToCancel, bookingId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { company , isCartLoading } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [cart , setCart] = useState<any>();
  const [cancellationConfirmation, setCancellationConfirmation] = useState<string>("");
  const { getCart } = useCart();
  const hasCalledAPI = useRef(false);

  const getCartData = async () => {
    const data = await getCart(cartId);

    const { bookings, addonPurchases, cart } = data;

    setCart(cart);
    const updatedBookings = mapBookingsWithAddons(bookings, addonPurchases);
    const { total, subTotal, discount } = calculateTotals(cart);

    setTotal(total);
    setSubTotal(subTotal);
    setDiscount(discount);
    setBookings(updatedBookings);
    if (location.pathname === "/confirmation") {
      triggerPurchaseEvent(updatedBookings, cart, cartId, company?.currency || '');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (hasCalledAPI.current) return;
      hasCalledAPI.current = true;
      await getCartData();
    };
    fetchData();
  }, []);

  const { cancelBooking } = useProfile();

  const handleCancelBooking = async (booking: Booking) => {
    if (shouldRedirectToCancel) {
      navigate(`/cancellation?companyId=${company?.id}&cartId=${cartId}`);
      return;
    }

    setIsLoading(true);
    try {
      await cancelBooking(booking.id);
      const isUnpaidBooking = subTotal === 0;
      setCancellationConfirmation(generateCancellationMessage(booking.date, booking.price, company?.currency || '', isUnpaidBooking));
      await getCartData();
    } catch (error: any) {
      toast(error.response?.data?.message || "Failed to cancel booking", {
        theme: "colored",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useInjectQualtricsScript({ companyId: company?.id!, cartData: cart, cartId });

  return (
    <ContentLoader isLoading={isLoading || isCartLoading}>
      
      <>
        <Typography color={"red"} style={{ marginBottom: "15px", textAlign: "center" }} data-testid="cancellation-confirmation">{cancellationConfirmation}</Typography>
        {bookings.length > 0 ? (
          <Stack sx={{ width: "100%", gap: "32px" }}>
            {bookings.filter((booking) => booking.id === bookingId || !bookingId).map((booking) => (
              <CartBookingCard booking={booking} isCancelAddon={false} key={booking.id} showCancelOption={true} onCancelBookingHandler={handleCancelBooking} />
            ))}
          </Stack>
        ) : (
          !cancellationConfirmation && <Typography variant="body1" style={{ textAlign: "center", marginBottom: "15px" }}>
            Unable to display bookings at this time. Please try again later.
          </Typography>
        )}
        {bookings.length > 0 && (
          <PricingCard
            subTotal={subTotal}
            total={total}
            discount={discount}
            vat={discount}
            isPaid={true}
          />
        )}
      </>
    </ContentLoader>
  );
};

export default CartCheckoutDetails;
