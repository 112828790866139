import React, { useState } from "react";
import { CardAddonRowProps } from "./Type";
import { Box } from "@mui/system";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import useCart from "../../../hooks/useCart";


const CardAddonRow: React.FC<CardAddonRowProps> = ({
  addon,
  isCancelAddon = true,
}) => {

  const [quantity, setQuantity] = useState(addon.quantity);

  const { removeAddonFromCart, updateAddonInCart } = useCart();

  const handleCancel = () => {
    removeAddonFromCart(addon);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setQuantity(parseInt(event.target.value));
    updateAddonInCart(addon, parseInt(event.target.value));
  };

  return (
    <Box sx={{ color: "#808080", mt: 2, mb: 1 }} data-testid="card-addon-row">
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
        sx={{ position: "relative" }}
      >
        <Typography
          className="truncate"
          sx={{ textAlign: "left" }}
        >
          <Select
            disabled={!isCancelAddon}
            value={quantity.toString()}
            onChange={handleChange}
            sx={{
              border: 0,
              pl: 3,
              py: 2,
              fontSize: "16px",
              fontWeight: "100",
              height: "23px",
              boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
              borderRadius: "8px",

              "&::before": {
                content: "none",
              },
              "& .MuiSelect-select": {
                padding: 0,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            }}
            data-testid="card-addon-row-quantity-select"
          >
            {Array.from({ length: addon.addon.maxQuantity || 10 }).map((_, index) => (
              <MenuItem key={index} value={`${index + 1}`}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>
          {addon.addon.name}
        </Typography>
        <Typography
          style={{
            marginLeft: "auto",
            textAlign: "right",
            fontSize: "12px",
            color: "#5182FF",
            fontWeight: "bold",
          }}
          data-testid="card-addon-row-price"
        >
          {addon.paidAmount} {addon.addon.currency}
        </Typography>

        {isCancelAddon && (
          <DeleteOutlineOutlinedIcon
            data-testid="card-addon-row-delete-icon"
            sx={{ cursor: "pointer", marginLeft: "15px" }}
            onClick={handleCancel}
          />
        )}
      </Stack>
    </Box>
  );
};

export default CardAddonRow;
