import { Tab, Tabs } from "@mui/material";
import React from "react";
import { IFormTabs } from "./Types";
import { RegistrationStep } from "../../../Utils/constants";

const FormTabs: React.FC<IFormTabs> = ({ formType, onChangeFormHandler }) => {
  return (
    <Tabs value={formType} onChange={onChangeFormHandler}>
      <Tab
        label={"Login"}
        value={RegistrationStep.Login}
        sx={{
          textTransform: "uppercase",
          color: "#000",
          fontFamily: "Roboto Condensed",
          fontSize: "18px",
          fontWeight: "bold",
          padding: "16px",
          width: "50%",
          maxWidth: "385px",
          borderBottom: `2px solid #000`,
          "&.Mui-selected": {
            color: (theme) => `${theme.palette.primary.main} !important`,
          },
        }}
        data-testid="login-tab"
      />
      <Tab
        label={"Sign Up"}
        value={RegistrationStep.SignUp}
        sx={{
          textTransform: "uppercase",
          color: "#000",
          fontFamily: "Roboto Condensed",
          fontSize: "18px",
          fontWeight: "bold",
          width: "50%",
          maxWidth: "385px",
          padding: "16px",
          borderBottom: `2px solid #000`,
          "&.Mui-selected": {
            color: (theme) => `${theme.palette.primary.main} !important`,
          },
        }}
        data-testid="signup-tab"
      />
    </Tabs>
  );
};

export default FormTabs;
