import React, { useContext } from "react";
import { Typography } from "@mui/material";
import PackageDetailsItem from "../package/PackageDetailsItem";
import { MembershipDetailsWrapper } from "./style";
import dayjs from "dayjs";
import { DSW_COMPANY_ID } from "../../../Utils/constants";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import { getCurrency } from "../../../Utils/format";

interface IProps {
  membershipPrice: number;
  membershipServiceName: string;
  membershipCode?: string;
  membershipValue?: number;
  membershipDuration?: number;
  title?: string;
  endDate?: string;
}

const MembershipDetails: React.FC<IProps> = ({
  membershipServiceName,
  membershipPrice,
  membershipCode,
  membershipValue,
  membershipDuration,
  title,
  endDate
}) => {

  const { company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;
  return (
    <MembershipDetailsWrapper>
      <Typography className="package-details-label" data-testid="membership-details-label">
        {title || ""}
      </Typography>
      {membershipCode && (
        <PackageDetailsItem label="Membership code " value={membershipCode} />
      )}
      <PackageDetailsItem label="Service " value={membershipServiceName} />

      <PackageDetailsItem
        label="Membership Price"
        value={`${membershipPrice} ${getCurrency(company?.currency)}`}
      />

      {!!membershipValue && <PackageDetailsItem
        label="Membership Value"
        value={`${membershipValue} Sessions`}
      />}

      {!!membershipDuration && <PackageDetailsItem
        label="Duration"
        value={(membershipDuration === 100 && company?.id === DSW_COMPANY_ID) ? "Full Season" : `${membershipDuration} days`}
      />}

      {endDate && <PackageDetailsItem
        label="Membership EndDate"
        value={dayjs(endDate).format("DD/MM/YYYY")}
      />}


    </MembershipDetailsWrapper>
  );
};

export default MembershipDetails;
