import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AddonItem from "../../components/addons/AddonItem";
import ContentLoader from "../../components/shared/ContentLoader";

import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import useAddons from "../../hooks/useAddons";
import useCart from "../../hooks/useCart";

import { AddOnsWrapper } from "./style";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { toast } from "react-toastify";
import useTitle from "../../hooks/useTitle";

interface IProps {
  title: string;
}

const AddOns: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedService,
    isCartLoading,
    selectedDuration,
    selectedSlot,
    selectedSession,
    setSelectedSession,
    setSelectedSlot,
    quantity,
    company,
    selectedResource,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const [selectedAddonsQuantities, setSelectedAddonsQuantities] = useState<Map<string, number>>(new Map());

  const { isLoading, addons } = useAddons(selectedService);
  const { addToCart } = useCart();

  const getAddonsList = () => {
    return Array.from(selectedAddonsQuantities, ([id, quantity]) => ({ id, quantity }));
  };

  const handleProceedToCheckout = async () => {
    const addedToCart = await addToCart({
      selectedService,
      selectedDuration,
      selectedSlot,
      quantity: quantity || 1,
      selectedSession,
      addonsList: getAddonsList(),
      childrenInformation: location.state?.childrenInformation,
      selectedResource,
      timezone: company?.timezone
    });

    if (addedToCart) {
      setSelectedSession(undefined);
      setSelectedSlot(undefined);
      navigate(`/CartCheckout?companyId=${company?.id}`);
    } else {
      toast("Error while adding the item to the cart", {
        theme: "colored",
        type: "error",
      });
    }
  };

  return (
    <ContentLoader isLoading={isLoading || isCartLoading}>
      <AddOnsWrapper className="equipment-container">
        <Box className="section">
          <Box className="equipment-options">
            <Typography className="equipment-header add-equipment">
              Add On Equipment
            </Typography>
          </Box>
          <Box className="products-section">
            <Box className="products-section-items items-container">
              {addons.map((addon) => (
                <AddonItem
                  key={addon.id}
                  addon={addon}
                  setSelectedAddonsQuantities={setSelectedAddonsQuantities}
                  selectedDurationTime={selectedDuration?.durationTime}
                />
              ))}
            </Box>
          </Box>
          <PrimaryBtn
            text="Proceed to Checkout"
            onClick={handleProceedToCheckout}
            sx={{ marginTop: "67px", width: "100%" }}
            data-testid="addons-proceed-to-checkout-button"
          />
        </Box>
      </AddOnsWrapper>
    </ContentLoader>
  );
};

export default AddOns;
