import React, { useContext } from "react";
import { Box } from "@mui/system";
import { CircularProgress, Stack, SxProps, Theme, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../../context/CheckoutContext";
import useCart, { Booking } from "../../../../hooks/useCart";
import { formatPriceValue, formatStringTime, getCurrency } from "../../../../Utils/format";
import { CartBookingCardRowWrapper } from "./style";

export interface CartBookingCardRowProps {
  booking: Booking;
  showRemoveFromCartOption?: boolean;
  sx?: SxProps<Theme>;
}

const BookingCardRow: React.FC<CartBookingCardRowProps> = ({
  booking,
  showRemoveFromCartOption,
  sx,
}) => {
  const { isCartLoading } = useContext(CheckoutContext) as CheckoutContextProps;
  const { removeFromCart } = useCart();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const handleRemoveFromCart = () => {
    if (booking) removeFromCart(booking);
  };

  

  return (
    <CartBookingCardRowWrapper>
      <Box className="time-duration">
        <Typography>{formatStringTime(booking.startTime)}</Typography>
        {booking.serviceDuration?.durationTime && <Typography>{booking.serviceDuration.durationTime} Min</Typography>}
      </Box>

      <Typography className="price" data-testid="booking-card-row-price">
        {formatPriceValue(booking.price, company?.currency)}
      </Typography>

      {showRemoveFromCartOption && (
        <Box className="remove-button" data-testid="booking-card-row-remove-button">
          {isCartLoading ? (
            <CircularProgress />
          ) : (
            <DeleteOutlineOutlinedIcon
              onClick={handleRemoveFromCart}
              data-testid="delete-icon"
            />
          )}
        </Box>
      )}
    </CartBookingCardRowWrapper>
  );
};

export default BookingCardRow;
