import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignUp from "../../components/register/signup";
import { useAuth } from "../../context/AuthContext";
import { RegisterWrapper } from "./style";
import { RegistrationStep } from "../../Utils/constants";
import CompleteProfile from "../../components/register/completeProfile";
import ContentLoader from "../../components/shared/ContentLoader";
import { isValidUser } from "../../Utils/validate";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useTitle from "../../hooks/useTitle";

interface IProps {
  title?: string;
  redirectToCalendar?: boolean;
  registrationStep: RegistrationStep;
}
const Register: React.FC<IProps> = ({
  title,
  redirectToCalendar = true,
  registrationStep,
}) => {
  useTitle(title);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [currentStep, setCurrentStep] =
    useState<RegistrationStep>(registrationStep);
  const { user, isAuthLoading } = useAuth();
  const [isSocialLogin, setIsSocialLogin] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");

  const {
    company
  } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    if (state) {
      setCurrentStep((state as any).registrationStep as RegistrationStep);
    }
  }, [state]);

  useEffect(() => {
    if (isValidUser(user) && currentStep !== RegistrationStep.CompleteProfile) {
      navigate(`/calendar?companyId=${company?.id}`);
    }
  }, [navigate, user, user?.id, isAuthLoading, currentStep]);

  const redirectToCompleteProfile = (
    socialRegister: boolean,
    userEmail?: string
  ) => {
    setIsSocialLogin(socialRegister);
    setCurrentStep(RegistrationStep.CompleteProfile);
    if (!socialRegister) setEmail(userEmail!);
  };

  const redirectToLogin = () => {
    setCurrentStep(RegistrationStep.Login);
  };

  return (
    <ContentLoader isLoading={isAuthLoading}>
      <RegisterWrapper data-testid="register-wrapper">
        {/* {currentStep === RegistrationStep.SignUp && (
          <SignUp redirectToCompleteProfile={redirectToCompleteProfile} />
        )} */}

        {currentStep === RegistrationStep.CompleteProfile ? (
          <CompleteProfile
            redirectToCalendar={redirectToCalendar}
            isSocialLogin={isSocialLogin}
            email={email}
            redirectToLogin={redirectToLogin}
            data-testid="complete-profile-component"
          />
        ) : (
          <SignUp
            redirectToCalendar={redirectToCalendar}
            redirectToCompleteProfile={redirectToCompleteProfile}
            currentStep={currentStep}
            data-testid="signup-component"
          />
        )}

        {/* {currentStep === RegistrationStep.Login && (
          <Login
            redirectToCalendar={redirectToCalendar}
            setCurrentStep={setCurrentStep}
          />
        )} */}
      </RegisterWrapper>
    </ContentLoader>
  );
};

export default Register;
