import React, { useContext } from "react";
import { Typography } from "@mui/material";

import PackageDetailsItem from "./PackageDetailsItem";
import { PackageDetailsWrapper } from "./style";
import { formatDuration, formatPrice } from "../../../Utils/format";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";

interface IProps {
  packageValue: number;
  packagePrice: number;
  packageCode?: string;
  packageName?: string;
  packageDuration?: number;
  type: string;
}

const PackageDetails: React.FC<IProps> = ({
  packageValue,
  packagePrice,
  packageCode,
  packageName,
  packageDuration,
  type
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;


  const formatTheValue = () => {

    if (type === "credit") {
      return formatPrice(packageValue, company);
    }

    if (type === "duration") {
      return formatDuration(packageValue, company?.serviceDurationMultiples!);
    }

    if (type === "membership") {
      return `${packageValue} Sessions`;
    }
  }

  return (
    <PackageDetailsWrapper data-testid="package-details">
      <Typography
        data-testid="package-details-label"
        className="package-details-label"
        sx={{ textAlign: "left" }}
      >{packageName}</Typography>
      {packageCode && (
        <PackageDetailsItem label="Package Code" value={packageCode} data-testid="package-item-code" />
      )}
      {!!packageValue && <PackageDetailsItem label="Package Value" value={formatTheValue()!} data-testid="package-item-value" />}
      <PackageDetailsItem label="Package Price" value={`${formatPrice(packagePrice, company)}`} data-testid="package-item-price" />
      {(!!packageDuration && packageDuration > 0) && <PackageDetailsItem label="Package duration" value={`${packageDuration} day${packageDuration > 1 ? "s" : ""}`} data-testid="package-item-duration" />}
    </PackageDetailsWrapper>
  );
};

export default PackageDetails;
